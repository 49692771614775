<template>
  <h2>Akce klubu</h2>
  <ul class="events">
    <li v-for="event in events" :key="event.id">
      <a :href="event.link">
        <h3>{{ event.title }}</h3>
        <div class="sold-out" v-if="event.vyprodano">
          <span>Vyprodáno</span>
        </div>
        <p v-else>{{ event.description }}</p>
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";

const events = [
  {
    id: "eve-1",
    title: "Oravice - Roháče",
    link: "/akce-klubu/oravice-rohace",
    description: "Lyžování na Slovensku",
  },
  {
    id: "eve-2",
    title: "Bystřička",
    link: "/akce-klubu/bystricka",
    description: "Sjezd divoké řeky",
  },
  // {
  //   id: "eve-3",
  //   title: "Řecko - korfu",
  //   link: "/akce-klubu/recko",
  //   description: "Řecko a potápění",
  // },
  {
    id: "eve-4",
    title: "Zlatý Dunajec",
    vyprodano: false,
    link: "/akce-klubu/dunajec",
    description:
      "Plavba kaňonem zaříznutým hluboko do skal, peřejnatá řeka, stromy nádherně obarvené podzimem...",
  },
  {
    id: "eve-5",
    title: "Vodácké a turistické kurzy",
    link: "/akce-klubu/kurzy",
    description: "",
  },
];
</script>

<style scoped>
.events {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.events li {
  border-bottom: 1px solid #ddd;
}

.events li a {
  display: block;
  padding: 10px;
  text-decoration: none;
}

.events li a h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.events li a p {
  font-size: 0.7rem;
}
</style>
