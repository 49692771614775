import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RohaceVue from '@/views/articles/Rohace.vue'
import BystrickaVue from '@/views/articles/Bystricka.vue'
import OrderFormView from '@/views/OrderFormView.vue';
import ReckoVue from '@/views/articles/Recko.vue';
import DunajecVue from '@/views/articles/Dunajec.vue';
import KurzyVue from '@/views/articles/Kurzy.vue';
import ContactViewVue from '@/views/ContactView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/objednavky',
    name: 'objednavky',
    component: OrderFormView
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: ContactViewVue
  },
  {
    path: '/akce-klubu/oravice-rohace',
    name: 'rohace',
    component: RohaceVue,
  },
  {
    path: '/akce-klubu/bystricka',
    name: 'bystricka',
    component: BystrickaVue,
  },
  // {
  //   path: '/akce-klubu/recko',
  //   name: 'recko',
  //   component: ReckoVue,
  // },
  {
    path: '/akce-klubu/dunajec',
    name: 'dunajec',
    component: DunajecVue,
  },
  {
    path: '/akce-klubu/kurzy',
    name: 'kurzy',
    component: KurzyVue,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
